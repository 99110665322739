<template>
  <div class="container">
    <ScrollNotice/>
    <div class="info item">
      <div class="info_left">
        <div> 
		
		  <img :src="this.apiUrl+pic"  style="width: 240px;height: 284px" fit="cover" alt="">
		  
          <div v-show="isFree" class="count">

            <p><span class="circle"></span>他在线，可以发信联系</p>
          </div>
          <div v-show="!isFree" class="count">

            <p><span class="circle"></span>正在咨询</p>
            <small v-if="!isFree && n>0">(尚可允许{{n}}人进入咨询，不包括已预约者)</small>
          </div>
        </div>
        <div class="options">
          <el-button @click="toChat">去咨询</el-button>
          <el-button   @click="setTtreatmentTime"  >预约挂号</el-button>
        </div>
      </div>
      <div class="info_middle">
        <div class="head">
          <div class="big">{{info.nickName }}</div>
          <div class="name">姓名：{{info.userName}}</div>
          <div class="id">ID：{{info.ids}}</div>
        </div>
        <div class="rate">
          <div class="rate_item">
            <span>技术满意度：</span>
            <el-progress :percentage="90" :format="format1"></el-progress>
          </div>
          <div class="rate_item">
            <span>文明满意度：</span>
            <el-progress :percentage="100" :format="format2"></el-progress>
          </div>
        </div>
        <div class="tip">
			{{info.describe}}
          <!-- <span>
            1.认证信息是会员自己提供，目前中国无完整渠道确保100%真实，请理性对待，谨防钱财欺诈等。
          </span>
          <span>
            2.大海医疗是在线医护平台，病人(会员本人)没有进行亲自诊察，基于患者自述病情(可能有夸大病情等不准确情况)
          </span> -->
        </div>
        <div class="info_detail">
          <el-row :gutter="20">
            <el-col :span="6" class="info_item">姓名：<span>{{info.userName}}</span></el-col>
            <el-col :span="6" class="info_item">性别：<span>{{info.sex === 1 ? '男' : '女'}}</span></el-col>
            <el-col :span="6" class="info_item">民族：<span><!-- {{info.race}} --> 汉族</span></el-col>
            <el-col :span="6" class="info_item">国籍：<span><!-- {{info.nationality}} -->中国</span></el-col>
            <el-col :span="6" class="info_item">年龄：<span>{{info.age}} 周岁</span></el-col>
            <el-col :span="6" class="info_item">身高：<span>{{info.height}} cm</span></el-col>
            <el-col :span="6" class="info_item">体重：<span>{{info.weight}} kg</span></el-col>
            <el-col :span="6" class="info_item">学历：<span>{{info.education}}</span></el-col>
            <el-col :span="6" class="info_item">专业：<span>{{info.studyMajor}}</span></el-col>
            <el-col :span="6" class="info_item">籍贯：<span>{{info.hometown_name}}</span></el-col>
            <el-col :span="24" class="info_item">家庭住址：<span>{{info.home_address_info}}</span></el-col>
          </el-row>
        </div>
      </div>
      <div class="info_right">
        <el-image class="environment" fit="cover" :src="envImg"/>
        <div class="help">
          <el-tooltip effect="dark" placement="right" popper-class="tip_text">
            <div slot="content">
              如涉嫌欺诈等，可以在投诉里给客服写信举报，可<br>提供相应证据材料;举报应本着实事求是的原则，<br>不得诬陷、诋毁、违规、违法，网站客服需要向对<br>方即被举报方核实，网站将进行相应的处理，比<br>如，予以取消会员资格等;如涉嫌违法，医患双方<br>可通过诉讼解决，与本网站无关
            </div>
            <div class="help_text">?</div>
          </el-tooltip>
        </div>
        <div class="nav" style="width:360px;">
			
          <span class="nav_item" @click="" v-if="this.info.isCollected">已收藏</span>
          <span class="nav_item" @click="saveCollect2" v-else>收藏</span>
		  
		  
          <el-divider direction="vertical"/>
          <span class="nav_item"  @click="sendGifts" >送礼物</span>
          <el-divider direction="vertical"/>
          <span class="nav_item" @click="goEvaluate"  >去评价</span>
          <el-divider direction="vertical"/> 
          <span class="nav_item" @click="goComment" >所有评价</span>
          <el-divider direction="vertical"/> 
		  <span class="nav_item" @click="" v-if="this.info.isBlock">已阻止</span>
		  <span class="nav_item" @click="setBlock" v-else>阻止</span>
          <el-divider direction="vertical"/>   
		  
		  <span class="nav_item" @click="goReport" v-if="this.info.isReport">已举报</span>
		  <span class="nav_item" @click="goReport" v-else>举报</span>
			
			
        </div>
      </div>
    </div>
    <div class="intro item">
      <div class="intro_left">
        <div class="title">
          个人业务技术简介
        </div>
        <div class="left_content">
          {{ info.memo }}
        </div>
        <div class="title">
          个人简要履历
        </div>
        <div class="left_content">
          {{info.resume}}
        </div>
      </div>
      <div class="intro_right">
        <el-image class="environment" fit="cover" :src="envImg"/>
      </div>
    </div>
    <div class="item">
      <div class="title">收到的礼物</div>
      <div class="gifts clearFix">
        <el-image :src="require('./img/gift.jpg')" class="gift" fit="cover" @click="sendGifts"/>
        <el-image v-for="(item, index) in gifts" :key="index" :src="item.url" class="gift" />
      </div>
    </div>
    <Gifts ref="gifts" :info="info"/>
    <Schedual ref="schedual"/>
    <Evaluate ref="Evaluate" :info="info" />
    <Report ref="Report" :info="info" />
    <Comment ref="Comment" :info="info" />
	 
	<set-ttreatment-time-dialog ref="setTtreatmentTime"></set-ttreatment-time-dialog>
  
  </div>
  
</template>	

<script>
	
  import axios from "axios"; 
  import ScrollNotice from '@/components/ScrollNotice'
  import Gifts from '@/components/Gifts'
  import Evaluate from '@/components/Evaluate'
  import Report from '@/components/Report'
  import Comment from '@/components/Comment'
  import Schedual from '@/components/Schedual'
  import {getMemo} from "../../api/DoctorApi";
  import {saveCollect} from "../../api/EvalApi"; 
  
  import SetTtreatmentTimeDialog from "../../views/children/service/components/SetUserTtreatmentTimeDialog";
  
  export default {
    name: "Doctor",
    components: {ScrollNotice, Gifts, Schedual,Evaluate,Report,Comment,SetTtreatmentTimeDialog},
    data() {
      return { 
		id:0,
        isFree: false,
        n: 2,
		gifts: [
		  // require('../../assets/gifts/git1.jpg'),
		  // require('../../assets/gifts/git2.jpg'),
		  // require('../../assets/gifts/git3.jpg'),
		  // require('../../assets/gifts/git4.jpg')
		],
		  info: {
          name: '张三',
          id: '12121212',
          tecRate: '4.5',
          civRate: '4.9',
          confirms: '',
          sex: '男',
          nation: '汉',
          country: '中国',
          age: '28岁',
          height: '178cm',
          weight: '65kg',
          education: '本科',
          major: '外科医生',
          native: '山东省',
          address: '山东省-济南市-市中区-详细地址',
          tecIntro: '',
          expIntro: '',
          memo: '由张正规品图去解米带为万织走实图马变清少道许适反体报代劳前油角品进听头队据律主八你共化发点个单接值派感真。劳性许法道强标及下二低集得及内员清提出的心电素比决须正花增却我下油可用作山即学业始离则感局消律农白但见得因即半术织你现。现万观信必受取社米知决亲极而回战总然省角百众都子连出个矿强观计治着象争办花决体价美省西至研期三阶般完引。需立长如明由为太着化研证高向象和解月断学人证火府族层水得界并满候社不边科设术法去便等况加原工离花斗形使题目成江斗前证影不记机流律才间则法手百到面。矿深性也书近查争保区更斗识形理没目主了基开王新对被子许十心东京林油计史志单下林领实叫的之数线无事空手音美什毛林极需受天十严花积当东张属半克。',
          resume: '你委活得东及资然科相养想质亲派日史正议越样候增经件结务政断片日单例在行经周北两车准按头种全教公白。按厂际进研张员也得联下向想更科并前毛管常产光由提议条民三况议约边于现任至多学须因住任代农县建入八细在各据作出美再置前所转果确。期九向二格非一参听起再常节么况毛因话切可龙南物采全值管志理走实度义照该先并式于于确。理下来利矿广速相节众况者什各林件界包应五者里千且此报式志在毛飞天位术采成形红温声率年。',
          gifts: [
            require('../../assets/gifts/git1.jpg'),
            require('../../assets/gifts/git2.jpg'),
            require('../../assets/gifts/git3.jpg'),
            require('../../assets/gifts/git4.jpg')
          ],
		  pic:'',
          avatar: require('./img/avatar.jpg'),
		  isBlock:'',
		  isReport:'',

        },
        envImg: require('./img/envirement.jpg'),

        doctorAccountId: 0,
      }
    },
	created(){
		
		var query=this.$route.query; 
		this.id = query.id; 
		
		const params = new URLSearchParams();
		params.append('uid', this.id); 
		params.append('uuid', sessionStorage.getItem('uid')); 
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getInfo',params)
		.then((response) => {   
			this.info = response.data.data;  
			
			this.pic =response.data.data.pic; 
		
		}) 
		.catch(function(error) {
		  console.log(error);
		});
		 
		
		const params2 = new URLSearchParams(); 
		params2.append('uid', this.id);  
		axios.post(this.apiUrl+'/api/user/getUserGifs',params2)
		.then((response) => {  
			this.gifts = response.data.data;  
		}) 
		.catch(function(error) {
		  console.log(error);
		});
		
		
		
		
		   
	},
    mounted() {
     // this.doctorAccountId = this.$route.params.id;

      // getMemo(this.$route.params).then(res => {
      //   console.log(res);
      //   this.info = res;
      // })
    },

    methods: {
		/**
		 * 看病预约设置
		 */
		setTtreatmentTime(){
		 
			this.$refs['setTtreatmentTime'].show(this.id);  
		},
		
		setBlock() {
		  this.$confirm('您确定拉黑对方吗？').then(_ => { 
		  
		  const params = new URLSearchParams();
		  params.append('uid', sessionStorage.getItem('uid'));
		  params.append('bid', this.id);   
		  axios.post(this.apiUrl+'/api/User/setBlock', params)
		  .then((response) => { 
		  			  
		  		 this.$message.success('操作成功');
		  		 this.info.isBlock = 1;
		  			  
		  			  
		    })
		  
		  
		    .catch(function(error) {
		      console.log(error);
		    });
			
			
		  
		  }).catch(_ => {
		   
		  
		  })
		},
		
	
		
      format1() {
        return this.info.tecRate
      },
      format2() {
        return this.info.civRate
      },
	  goEvaluate(){ 
		this.$refs.Evaluate.open()
	  },
      sendGifts() {
        this.$refs.gifts.open()
      },
	  
	  goComment(){
        this.$refs.Comment.open()
		  
	  },
	  
	  goReport(){ 
	  		this.$refs.Report.open()
	  },

      /**
       * 收藏的操作
       */
	  saveCollect2(){
	    const params = new URLSearchParams();
	    params.append('uid', sessionStorage.getItem('uid'));
	    params.append('bid', this.id); 
	    
	    
	    axios.post(this.apiUrl+'/api/User/addCollection', params)
	    .then((response) => { 
	    			  
	    		 this.$message.success('操作成功');
				 this.info.isCollected = 1;
	    			  
	    			  
	      })
	    
	    
	      .catch(function(error) {
	        console.log(error);
	      });
	  },
      saveCollect(){
        saveCollect(this.doctorAccountId).then(() => {
          this.info.isCollected = true;
        })
      },

      /**
       * 打开咨询页面
       */
      toChat(){
        this.$router.push({"name":"在线聊天", query:{did : this.id}}) 
      }
    }
  }
</script>

<style scoped lang="scss">
  .container {
    .item {
      box-sizing: border-box;
      margin: 30px auto;
      background-color: #fff;
      border-radius: 8px;
      padding: 30px;
      width: 1200px;
      color: $gray;
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 490px;

      .info_left {
        color: $deep-gray;
        width: 240px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .count {
          text-align: center;

          .circle {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $green;
            margin-right: 8px;
          }
        }

        .options {
          display: flex;
          flex-direction: column;

          .el-button {
            margin-bottom: 10px;
            width: 135px;
            height: 32px;
            margin-left: 0px;
          }
        }
      }

      .info_middle {
        width: 470px;
        padding-top: 22px;
        padding-left: 20px;

        .head {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          margin-bottom: 20px;

          .big {
            font-size: 20px;
            font-weight: 600;
            margin-right: 30px;
            color: $deep-gray;
          }

          .name {
            margin-right: 50px;
            font-size: 14px;
          }
        }

        .rate {
          .rate_item {
            span {
              font-size: 12px;
            }

            .el-progress {
              display: inline-block;
            }
          }
        }

        .tip {
          font-size: 10px;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
          margin: 23px 0 57px;
          letter-spacing: 0;

          span {
            display: block;
          }
        }

        .info_detail {
          font-size: 12px;
          color: #666;
          line-height: 17px;

          .info_item {
            margin-bottom: 14px;
          }

          span {
            font-size: 14px;
            color: #333;
          }
        }
      }
    ;

      .info_right {
        width: 334px;
        position: relative;

        .help {
          float: right;

          .help_text {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: #e6e6e6;
            font-size: 14px;
            color: #999999;
            text-align: center;
            line-height: 26px;
            font-weight: 600;
            margin-top: 16px;
            text-indent: 2px;
          }
        }

        .tip_text {
          width: 300px !important;
        }

        .nav {
          position: absolute;
          bottom: 20px;
          right: 0;
          height: 20px;
          line-height: 20px;
          color: #666;

          .nav_item {
            cursor: pointer;
          }

          .el-divider--vertical {
            height: 10px;
            width: 2px;
            margin: 0 5px;
            background-color: #999;
            vertical-align: unset;
          }
        }
      }
    }

    .intro {
      min-height: 490px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .intro_left {
        width: 746px;

        .left_content {
          margin-bottom: 47px;
          font-size: 14px;
          line-height: 20px;
          color: #666666;
          min-height: 118px;
          letter-spacing: 0;
        }
      }

      .intro_right {
        width: 334px;
      }
    }

    .environment {
      width: 100%;
      height: 284px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      height: 25px;
      line-height: 25px;
      margin-bottom: 23px;
    }

    .gifts {
      .gift {
        float: left;
        width: 135px;
        height: 135px;
        margin-right: 20px;
      }
    }
  }

  .info /deep/ .el-progress-bar__inner {
    background-color: $green !important;
  }

  .info /deep/ .el-progress-bar {
    width: 150px;
  }

</style>
